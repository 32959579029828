import React from "react"
import { Link } from "gatsby"
import Layout from "../components/common/layout/layout"
import { Container } from "../components/global"
import styled from "styled-components"
import Footer from "../components/sections/footer"
import {useStaticQuery, graphql} from 'gatsby';




const Gallery = () => {
const data = useStaticQuery(
  graphql`
        query CloudinaryImage {
            allCloudinaryMedia {
            edges {
                node {
                    secure_url
                    context {
                        custom {
                            alt
                            caption
                        }
                    }
                    resource_type
                }
            }
            }
        }`
);
const images = data.allCloudinaryMedia.edges;
return (
  <Layout>
    <HeaderWrapper>
      <Link to="/">
        <Button>Back to homepage</Button>
      </Link>
    </HeaderWrapper>
      <PictureWrapper>
        {images.map((image, index) => (
          <figure className="wave" key={`${index}-image`}>

              <Picture
                src={image.node.secure_url}
                alt={image.node.context.custom.alt} >
              </Picture>

          </figure>
        ))
        }
      </PictureWrapper>
    <Footer/>
  </Layout>

  )

}

const Picture = styled.img`
  @media (max-width: ${(props) => props.theme.screen.md}) {
    max-width: 375px;
    height: auto;
  }
  width: 600px;
  height: 600px;
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const PictureWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Button = styled.button`
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  color: ${(props) => props.theme.color.primary};
  padding: 1em;
  margin-left: 8px;
  border-radius: 5px; 
  text-decoration: none;
  background-color: #ffd502;
  border: 3px solid black;
  font-weight: bolder;
  margin-left: 2.5em;
  margin-top: 3em;
`

export default Gallery;